var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("ASuccessFour", { attrs: { api: _vm.api } }),
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            [
              _c(
                "div",
                { staticClass: "d-flex justify-start" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3 mx-0 px-0",
                      attrs: { color: "primary" },
                      on: {
                        click: function () {
                          _vm.$router.go(-1)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-4" },
                        [
                          _c("ComponentCompanyList", {
                            on: {
                              selectCompany: function (resp) {
                                this$1.companyId = resp
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-4" },
                        [
                          _c("PageSelectForm", {
                            attrs: { companyId: this.companyId },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }