var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: { close: _vm.closeSuccessModal },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c("v-autocomplete", {
                    attrs: {
                      dense: "",
                      outlined: "",
                      items: _vm.formType,
                      label: "Please select the form",
                      "return-object": "",
                    },
                    model: {
                      value: _vm.formSelected,
                      callback: function ($$v) {
                        _vm.formSelected = $$v
                      },
                      expression: "formSelected",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(_vm.formSelected.value, {
                    tag: "component",
                    attrs: { companyId: _vm.companyId },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }